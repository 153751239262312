import React from "react"
import ReactDOM from "react-dom"
import Picture from '../images/Profilephoto.jpg';
import Emailicon    from "../images/Mail.png"
import Linkedinicon    from "../images/linkedin.png"


function Info() {

    return (

        <div className="info-ctn">
            <img className="profile-image" src={Picture} alt="Profile Picture" />
            <h1>Hubert Villalobos</h1>
            <h5>Frontend Developer</h5>
            <h6><a className="website-link" href= "https://hubertvillalobos.dev" target="_blank" rel="noopener noreferrer">hubertvillalobos.dev</a></h6>
            <div className="btn-container">            
            <a  className= "link-btn" href="mailto:hubert@villalobosmedia.com?body=My custom mail body" rel="noopener noreferrer"><button  type="button"  className="btn-email"> <img src={Emailicon} className="Emailicon" /> Email</button></a>
            <a className= "link-btn" href="https://www.linkedin.com/in/hubert-villalobos-huerta-8446981b0" target="_blank" rel="noopener noreferrer"><button type="button"  className="btn-linkedin" > <img src={Linkedinicon} className="Linkedinicon"/> Linkedin</button></a>
            </div>







        </div>




    )





}

export default Info;