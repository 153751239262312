import React from "react"
import ReactDOM from "react-dom"


function About() {

    return (
        <div className="about-ctn">
        <h2 >About</h2>
        <p className="about-text">I am a frontend developer with a particular interest in making things simple and automating daily tasks. 
            I try to keep up with security and best practices, and am always looking for new things to learn.</p>
        </div>





    )





}

export default About;