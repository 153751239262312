import React from "react"
import ReactDOM from "react-dom"
import twittericon from "../images/Twitter Icon.png"
import facebookicon from "../images/Facebook Icon.png"
import instagramicon from "../images/Instagram Icon.png"
import githubicon from "../images/GitHub Icon.png"


function Footer() {

    return (

        <div className="footer-ctn">
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><img className="twitter icon"  src={twittericon} /></a>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><img className="facebook icon" src={facebookicon} /></a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><img className="instagram icon" src={instagramicon} /></a>
            <a href="https://github.com" target="_blank" rel="noopener noreferrer"><img className="github icon" src={githubicon} /></a>

        </div>




    )





}

export default Footer;