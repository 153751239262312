import React from "react"
import ReactDOM from "react-dom"


function Interests() {

    return (
        <div className="interests-ctn">
        <h2>Interests</h2>
        <p className="interests-text">Food expert. Music scholar. Reader. Internet fanatic. Bacon buff. Entrepreneur. Travel geek. 
            Pop culture ninja. Coffee fanatic.</p>
        </div>




    )





}

export default Interests;